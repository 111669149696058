.c-status-label {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $color-white;
  border-radius: 4px;
  width: 13.75rem;
  justify-content: center;
  padding: 0.2rem 0;

  &--valid {
    background-color: $color-status-valid;
  }
  &--expiring {
    background-color: $color-status-expiring;
  }
  &--expired {
    background-color: $color-status-expired;
  }
  &--pending {
    background-color: $color-status-pending;
  }
  &--invalid {
    background-color: $color-status-invalid;
  }
  &--notapplicable {
    background-color: $color-status-notapplicable;
    color: $color-gray-800;
  }
}
.c-status-label-mini {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $color-white;
  border-radius: 4px;
  width: 10.25rem;
  justify-content: center;
  padding: 0.2rem 0;

  &--valid {
    background-color: $color-status-valid;
  }
  &--expiring {
    background-color: $color-status-expiring;
  }
  &--expired {
    background-color: $color-status-expired;
  }
  &--pending {
    background-color: $color-status-pending;
  }
  &--invalid {
    background-color: $color-status-invalid;
  }
  &--notapplicable {
    background-color: $color-status-notapplicable;
    color: $color-gray-800;
  }
}
