.c-invite-card {
  background: $color-gray-000;
  width: 100%;
  padding: 4rem;
  &__title {
    font-size: 2.25rem;
    color: $color-action-blue-700;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__description {
    width: fit-content;
    display: block;
    min-width: 200px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    flex: 1;
  }
}
