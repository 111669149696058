table {
  border-radius: $border-radius;
  border-collapse: collapse;
}
th,
td {
  padding: $spacing-large 8px;
}
thead {
  background: $color-gray-000;
  border: 0;
  border-radius: $border-radius;
  box-shadow: none !important;
  padding: $spacing-small 8px;
  font-size: $font-size-sm;
}
thead th {
  text-transform: uppercase;
  text-align: left;
  color: $color-gray-500;
}
tbody tr {
  border-bottom: 1px solid $color-gray-000;
}
