.c-choose-date {
  position: relative;

  .c-btn-clear-date {
    height: 1rem;
    min-height: auto;
  }

  .react-datepicker-wrapper {
    max-width: 15rem;
  }

  .react-datepicker-popper {
    transform: scale(2) translate(0, 55px) !important;
  }

  .react-datepicker__tab-loop {
    position: relative;
    z-index: 999;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }

  .react-datepicker__header {
    background-color: $color-white;
    border-bottom: none;
  }

  .react-datepicker {
    border: none;
    box-shadow: 0px 1px 8px $color-date-picker-box-shadow;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    color: $color-white !important;
    background-color: $color-date-picker-selected-date;
  }
  .react-datepicker__day-name {
    margin: 0;
    width: 3rem;
    line-height: 1;
    color: $color-date-picker-calendar-text;
  }
  .react-datepicker__current-month {
    margin: 0.5rem 0 1.5rem 0;
    color: $color-date-picker-calendar-text;
  }
  .react-datepicker__day {
    border: none;
    margin: 0;
    padding: 1rem;
    line-height: 1;
    width: 3rem;
    border-radius: 0;
    color: $color-date-picker-calendar-text;

    &:hover {
      border-radius: 0;
    }
  }
  .react-datepicker__navigation--previous {
    background: url('../../assets/icons/icon-chevron-left.svg') no-repeat;
    background-position: center;
    transform: scale(0.5);
    border: 1px solid $color-date-picker-chevron;
    border-radius: 100px;
  }
  .react-datepicker__navigation--next {
    background: url('../../assets/icons/icon-chevron-right.svg') no-repeat;
    background-position: center;
    transform: scale(0.5);
    border: 1px solid $color-date-picker-chevron;
    border-radius: 100px;
  }

  .react-datepicker__navigation-icon {
    display: none;
  }

  &--disabled-label {
    color: $color-date-picker-disabled-label !important;
  }

  .c-date-picker {
    border: none;
    height: auto;
    padding: 0 0 0 3rem !important;
    border-radius: 0;
    cursor: pointer;
    background-image: url('../../assets/icons/icon-calendar.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-color: transparent;
    color: $color-date-picker-text;

    &:focus {
      outline: none;
      border-color: transparent;
      box-shadow: none;
      color: $color-date-picker-focus-text !important;

      &::placeholder {
        opacity: 0 !important;
      }
    }

    &:hover {
      color: $color-date-picker-text-hover;
      background-image: url('../../assets/icons/icon-calendar-hover.svg');

      &::placeholder {
        color: $color-date-picker-text-hover;
      }
    }

    &:disabled {
      color: $color-date-picker-text-disabled;
      background-image: url('../../assets/icons/icon-calendar-disabled.svg');

      &::placeholder {
        color: $color-date-picker-text-disabled;
      }
    }

    &::placeholder {
      color: $color-date-picker-text;
    }

    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }
}
