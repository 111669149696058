.c-agreement-box {
  margin: 0 0 4rem 0;
  padding: 4rem;
  background-color: $color-box-bg;
  border-radius: $border-radius;
  color: $color-box-title;
  & > * {
    margin: 2rem 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  &__filter.rbt {
    border: 1px solid #0088cc;
    height: 5rem;
    border-radius: 26px;
    padding: 0.7rem 1rem;
    position: relative;
    cursor: pointer;
  }

  .c-choose-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rbt-input {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .rbt-input-hint-container {
    float: left;
  }

  &__grid-layout {
    display: grid;
    grid-template-columns: 25% 11% 11% 11% 11% auto;
  }
  &__grid-layout-license {
    display: grid;
    grid-template-columns: 25% 11% 11% 18% auto;
  }

  &__grid-layout-header {
    display: grid;
    grid-template-columns: 12% 12% 12% 12% auto;
  }

  &__grid-layout-annual {
    display: grid;
    grid-template-columns: 13% 12% 11% 11% 11% 11% auto;
  }

  &__grid-layout-one-date {
    display: grid;
    grid-template-columns: 25% 52% auto;
  }

  &__annual-fees-grid-layout {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 12% 12% 13% 13% 13% 13% auto;

    p {
      margin: 0;

      &:first-child {
        grid-column-start: 2;
      }
    }
  }

  &__textbox-grid-layout {
    display: grid;
    grid-template-columns: 30% auto;

    textarea {
      width: 100%;
      grid-column-start: 2;
      border-radius: 1rem;
      border: 1px solid $color-agreement-box-border;
      padding: 2rem;
      resize: none;
    }
  }

  &__title {
    color: $color-agreement-box-title;
    font-weight: 600;

    &.no-top-margin {
      margin-top: 0px;
    }

    &--subtext {
      color: $color-agreement-box-subtext;
      font-weight: 400;
      font-size: 1.5rem;
      padding-left: 1rem;
    }
  }

  &__doc-box {
    overflow: hidden;
  }

  &__doc-link {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    padding: 0 0 0.3rem 0;
    border: 0;
    min-height: auto;
    font-weight: 400;
  }

  &--upload {
    font-weight: 400;
    padding-bottom: 0.5rem;
    min-height: auto;

    div {
      align-items: initial;
    }

    i {
      font-size: 2.5rem;
    }
  }

  &--outline {
    @extend .c-agreement-box;
    background-color: $color-white;
    border: 4px solid $color-report-border;
  }

  &__align-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      padding: 0;
      margin: 0;
    }
  }

  &__align-bottom {
    display: flex;
    align-items: flex-end;
  }
  &__align-center {
    display: flex;
    align-items: center;
  }

  &__delete-btn {
    transform: scale(1.2);
    padding-top: 0.5rem;
  }

  &__date {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &.justify-center {
      justify-content: center;
    }

    &.width-60 {
      width: 60%;
    }

    &.padding-bottom {
      padding-bottom: 5px;
    }

    label {
      color: $color-agreement-box-title;
    }

    &--error {
      color: $color-utility-error-2;

      label {
        color: $color-utility-error-2;
      }
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__date-wrap {
    padding-left: 2rem;
  }

  &__applicable-checkbox {
    height: 100% !important;
  }

  &__applicable-text {
    margin-left: 1rem !important;
    color: $color-agreement-box-title;
  }

  &__new-year {
    padding-left: 0;
    font-weight: 400;
  }

  &__see-more {
    font-weight: 400;
  }
  &__year-input {
    height: 4rem !important;
    width: 16rem;
  }
  &__select {
    margin-right: 4rem;
    padding-bottom: 30px;

    &.no-padding {
      padding-bottom: 0px;
    }

    input {
      color: $color-agreement-box-select;
    }
  }
  .rbt-input-multi {
    max-width: 100%;
    white-space: initial;
  }
}
