@import '../../styles/molecules/select';

.c-react-table {
  border: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  &__table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    min-width: auto !important;

    &__thead {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      background: $color-gray-000;
      border: 0;
      border-radius: 10px;
      box-shadow: none !important;
      padding: 1rem 8px;
      user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      width: fit-content;
      min-width: 100%;

      &__tr {
        flex: 1 0 auto;
        display: inline-flex !important;
        text-align: left;
        text-transform: uppercase;
        font-size: $font-size-sm;
        color: $color-gray-500;
        font-weight: 500;
      }

      &__th,
      &__td {
        box-shadow: none;
        padding: 5px 5px;
        border-right: 0;
        width: 100px;
        cursor: pointer;
        white-space: normal;
        word-break: break-word;
        overflow: visible;
        line-height: normal;
        position: relative;
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;
        text-overflow: ellipsis;

        &__content {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__sort {
          display: inline-block;
          position: absolute;
          width: 10px;
          top: 5px;
          bottom: 0;
          right: 15px;
          z-index: 10;
        }

        &__resizer {
          display: inline-block;
          position: absolute;
          width: 20px;
          top: 0;
          bottom: 0;
          right: -6px;
          cursor: col-resize;
          z-index: 10;
        }
      }
    }

    &__tbody {
      margin-left: 5px;
      flex: 99999 1 auto;
      display: flex;
      flex-direction: column;

      &__tr-group {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      }

      &__tr {
        flex: 1 0 auto;
        display: inline-flex;
      }

      &__td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 7px 5px;
        transition: 0.3s ease;
        transition-property: width, min-width, padding, opacity;
        border-right: 0 !important;
        min-height: 6rem;
        display: flex;
        align-items: center;

        &__cell {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__empty-message {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
    color: $color-gray-500;
    text-align: center;
  }

  &__pagination {
    background: $color-gray-000;
    border: 0;
    border-radius: 10px;
    box-shadow: none;
    padding: $spacing-small;
    row-gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    input {
      border-radius: 3rem;
      padding: 0 2rem;
      height: 2.7rem;
      background-color: white;
      border: 0;
      color: $color-action-blue-300;
      height: 5rem;
      font-size: inherit;
      font-weight: normal;
      outline-width: 0;
    }

    select {
      cursor: pointer;
    }

    input,
    select {
      border-radius: 3rem;
      padding: 0 2rem;
      height: 2.7rem;
      background-color: white;
      border: 0;
      color: $color-action-blue-300;
      height: 5rem;
    }

    &__btn {
      color: $color-btn-outline;
      background-color: white;
      width: inherit;
      height: 5rem;
      padding: 0 2rem;
      border-radius: 50px;
      margin-right: 0;
      appearance: none;
      display: block;
      border: 0;
      font-size: 1em;
      transition: all 0.1s ease;
      outline-width: 0;

      &:hover {
        background-color: $color-action-blue-300 !important;
      }
    }

    &__btn[disabled] {
      opacity: 0.5;
      cursor: default;
      &:hover {
        background-color: $color-gray-100 !important;
        cursor: not-allowed;
      }
    }

    &__previous {
      flex: 1 1;
      text-align: center;
    }

    &__next {
      flex: 0 0;
    }

    &__center {
      flex: 1.5 1;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: initial;

      &__page-info {
        margin: auto 10px;
        display: inline-block;
        white-space: nowrap;

        &__page-jump {
          display: inline-block;

          input {
            border-radius: 20px;
            border: 0;
            background-color: white;
            width: 70px;
            text-align: center;
          }
        }
      }

      &__page-info {
        margin: auto 10px;

        select {
          border-radius: 3rem;
          padding: 0 2rem;
          background-color: white;
          border: 0;
          color: $color-action-blue-300;
          height: 5rem;
          cursor: pointer;
        }
      }
    }
  }
}
