.c-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &__upgrade-btn {
    background-color: $color-action-blue-700;
    border-color: $color-action-blue-700;
    &:focus,
    &:active,
    &:hover {
      background-color: $color-action-blue-500;
      border-color: $color-action-blue-500;
    }
  }
}
