.u-text-center {
  text-align: center !important;
}

.u-font-size-small {
  font-size: $font-size-sm !important;
}

.u-font-weight-500 {
  font-weight: 500;
}

.u-font-weight-bold {
  font-weight: bold;
}

.u-font-mono {
  font-family: $font-family-mono;
}

.u-text-transform-initial {
  text-transform: initial !important;
}

.u-text-success {
  color: $color-utility-success;
}

.u-text-error {
  color: $color-error;
}

.u-text-success {
  color: $color-success;
}
