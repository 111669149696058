.map-overlay {
  &__title {
    position: absolute;
    top: 3rem;
    left: 4rem;
  }

  &__menu {
    position: absolute;
    top: 25%;
    right: 2rem;
  }

  &__close-button {
    position: absolute;
    top: 4rem;
    right: 4rem;
    padding: 0;

    i {
      font-size: 3rem;
    }
  }
}

.c-map-marker {
  background: $color-marker-background;
  border-radius: 1rem;
  width: 16.5rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  border: none;

  &:hover {
    transform: scale(1.2);
    opacity: 1;
  }

  &:disabled {
    cursor: default;
  }

  &__region-name {
    font-weight: 500;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
    margin: 0;
    font-family: $font-family-primary;
    color: $color-marker-region-color;
  }

  &__vendor {
    display: flex;
    justify-content: space-evenly;
    color: $color-marker-vendor-count-color;
    padding-bottom: 2rem;

    &__count {
      border-radius: 0.5rem;
      font-weight: $font-weight-bold;
      padding: 0;
      width: 6rem;
      height: 6rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family-primary;
      font-size: $font-size-med;

      &--active {
        background-color: $color-marker-active-background;
      }

      &--inactive {
        background-color: $color-marker-inactive-background;
      }

      &--empty {
        background-color: $color-marker-empty-background;
      }
    }
  }
}

.c-map-menu {
  background: $color-marker-background;
  border-radius: 1rem;
  padding: 4rem 4rem 5.5rem 4rem;
  max-width: 38rem;

  &__title {
    font-weight: $font-weight-bold;
    margin: 0;
    font-family: $font-family-primary;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      height: 3.5rem;
    }

    &__vendor {
      padding: 0;
      font-weight: $font-weight-normal;
    }
  }
}
