.c-field {
  position: relative;
  margin-bottom: 2rem;
  &__label {
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
  &__input-icon {
    position: absolute;
    padding: 11px 14px 0 0;
    right: 0;
  }
  &__note {
    color: $color-gray-600;
  }
  &__warning {
    color: $color-utility-warning;
  }
  &__error {
    color: $color-utility-error;
  }
  &__toolTip {
    color: $color-links;
  }
  &__content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .c-input {
      margin-right: 1rem;
    }
  }
  input:disabled {
    background: $color-gray-000;
  }
  input::placeholder {
    color: $color-gray-500;
  }
}

.c-field .c-input__error,
.c-field .c-input__error:focus {
  border-color: $color-utility-error;
  box-shadow: 0 0 0 0.15em #e6171740;
}
