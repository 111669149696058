.c-tag {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-flow: column;
  grid-gap: 1rem;
  padding: 0.25rem;
  border-radius: 100px;
  background: $color-tabset-bg;
  color: $color-tabset-text;

  text-align: center;
  margin-bottom: 4rem;

  .c-tag__item {
    white-space: nowrap;
    border-radius: 100px;
  }
}

.c-tag-success {
  @extend .c-tag;
  background: transparent;
  color: $color-utility-success;
}

.c-tag-error {
  @extend .c-tag;
  background: transparent;
  color: $color-utility-error;
}
