.c-status-led {
  display: flex;
  align-items: center;
  &__label {
    text-transform: uppercase;
    font-size: $font-size-sm;
    color: $color-gray-700;
  }
  &__light {
    margin-right: 0.5rem;
    &--active {
      fill: $color-utility-success;
    }

    &--error {
      fill: $color-utility-error;
    }
    &--inactive {
      fill: $color-gray-600;
    }
    &--warning {
      fill: $color-utility-warning;
    }
  }
}
