.c-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__image {
    width: 100%;
    max-width: 40rem;
  }
  &__plan {
    color: $color-white;
    padding: 1.1rem $spacing 0.5rem $spacing;
    margin: 0 0 0 0.5rem;
    border-radius: 0.4rem;
    text-transform: uppercase;
    font-size: $font-size-sm;
    &--standard {
      background: $color-gray-500;
    }
    &--plus {
      background: $color-action-blue-700;
    }
    &--pro {
      background: $brand-red;
      width: 15rem;
    }
  }
}
