.c-label-led {
  background-color: $color-label-led-bg;
  padding: 0.7em $spacing-large;
  border-radius: $border-radius-lg;
  text-transform: uppercase;
  &__label {
    color: $color-label-led-text;
  }
  &--active {
    background-color: $color-label-led-bg-active;
  }
}
