html {
  font-size: 8px;
}

svg {
  height: 2.1rem;
  width: 2.5rem; // 20px
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80rem;
  width: 100%;
  top: 4rem;
  bottom: 0;
  left: 0;
  right: 0;
  & > * {
    height: 20%;
    width: 20%;
    fill: $color-action-blue-500;
  }
}

.spinner-blur {
  @extend .spinner;
  position: absolute !important;
}

.spinning {
  animation: spin 1.1s infinite;
  display: inline-block;
}

.loading-small {
  img {
    width: 2em;
    height: 2em;
  }
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
