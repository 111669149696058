.c-signup-link {
  background-color: $color-box-bg;
  border-radius: 10px;
  padding: $spacing-xlarge;
  margin-bottom: $spacing-xlarge;

  &__title {
    margin: 3rem 0;
    text-transform: uppercase;
  }

  &__fields {
    margin: 3rem 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__footer-right {
    display: flex;
    justify-content: space-between;
  }
}
