dl {
  display: grid;
  grid-template-columns: minmax(17.5rem, 15%) auto;
  margin: 0.5rem;
}

dt {
  grid-column: 1;
  font-weight: $font-weight-bold;
  color: $color-gray-500;
}

dd {
  grid-column: 2;
  margin-left: 1rem;
}
