.c-toggle {
  position: relative;
  min-width: 14rem;
  width: 15rem;
  height: 5rem;
  border-radius: 50px;
  border: 0;
  background-color: $color-toggle-bg;
  color: #fff;
  overflow: hidden;
  cursor: pointer;

  .c-toggle__handle {
    position: absolute;
    width: 23px;
    height: 23px;
    top: 1rem;
    left: 1rem;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    transition: 0.2s ease all;
    background-color: $color-toggle-handle;
    border-radius: 50px;
    z-index: 2;
  }

  &__label-active,
  &__label-inactive {
    position: absolute;
    text-transform: uppercase;
    top: 1rem;
  }

  &__label-active {
    left: 3rem;
  }

  &__label-inactive {
    right: 1.5rem;
    color: $color-toggle-label;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--active {
    background-color: $color-toggle-bg-active;
    .c-toggle__handle {
      left: auto;
      right: 1rem;
      background-color: $color-toggle-handle-active;
    }
  }
}
