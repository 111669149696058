textarea {
  color: $color-body-text;
  border-color: $color-body-text;
  border-radius: 0.3rem;
  padding: 1rem;
  &:focus {
    border-color: $color-action-blue-500;
    outline: $color-action-blue-500;
    box-shadow: 0 0 0 0.05em lighten($color-action-blue-500, 35%),
      0 0 0.15em 0.1em lighten($color-action-blue-500, 35%);
  }
}
