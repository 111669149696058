.c-box {
  margin: 0 0 4rem 0;
  padding: 4rem;
  background-color: $color-box-bg;
  border-radius: $border-radius;
  color: $color-box-title;
  & > * {
    margin: 2rem 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  &__title {
    font-size: $font-size-lg;
    margin: 0;
    line-height: 2.4rem;
  }
  &--outline {
    @extend .c-box;
    background-color: $color-white;
    border: 4px solid $color-report-border;
  }
  &--promo-plus {
    background-color: $color-box-bg-dark;
    color: $color-white;
    padding: 4rem;
    width: 100%;
    .c-box__title {
      color: $color-white;
    }
    .c-box__cta-btn {
      background-color: $color-white;
      color: $color-action-blue-300;
      border-color: $color-white;
      &:focus,
      &:hover,
      &:active {
        background-color: $color-action-blue-300;
        color: $color-white;
        border-color: $color-action-blue-300;
      }
    }
  }
}
