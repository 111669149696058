.c-tabset {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 1rem;
  padding: 0.5rem;
  border-radius: 100px;
  background: $color-tabset-bg;
  color: $color-tabset-text;
  text-align: center;
  margin-bottom: 4rem;

  .c-tab__item {
    white-space: nowrap;
    border-radius: 100px;
    padding: 0.35rem 3rem;
    cursor: pointer;
    &--active {
      background: $color-tabset-bg-active;
      color: $color-tabset-bg;
    }
  }
}
