.c-notice {
  background: $color-gray-000;
  width: 100%;
  padding: 4rem 4rem;
  margin: 0 0 4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius;
  &__warning {
    background: $color-utility-warning;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__content-alt {
    display: block;
  }
  &__title {
    margin: 0;
  }
  &__text {
    margin: 0 2rem 0;
  }
  &__text-alt {
    margin: 0;
  }
  &--column {
    flex-direction: column;

    .c-notice__content {
      padding-bottom: 2rem;
      flex-direction: column;
    }
    .c-notice__title {
      padding-bottom: 2rem;
    }
    .c-notice__buttons {
      width: 100%;
      justify-content: center;
    }
  }
  &--promo-plus {
    background-color: $color-box-bg-dark;
    color: $color-white;
    padding: 4rem;
    width: 100%;
    .c-notice__title {
      color: $color-white;
    }
    .c-notice__cta-btn {
      background-color: $color-white;
      color: $color-action-blue-300;
      border-color: $color-white;
      &:focus,
      &:hover,
      &:active {
        background-color: $color-action-blue-300;
        color: $color-white;
        border-color: $color-action-blue-300;
      }
    }
  }
  @media all and (max-width: $bp-large) {
    flex-direction: column;

    &__content {
      padding-bottom: 2rem;
      flex-direction: column;
    }
    &__title {
      padding-bottom: 2rem;
    }
    &__buttons {
      width: 100%;
      justify-content: center;
    }
  }
}
