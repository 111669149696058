* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0px;
}

// important fighting to
html > body {
  padding: 0 !important;
}

input {
  background-color: transparent;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
}

svg {
  overflow: visible;
}

.aa-todo {
  background-color: pink;
  border-radius: 7px;
}

// abstracts
@import './styles/abstracts/variables';
@import './styles/abstracts/colors';
@import './styles/abstracts/mixins';

// utilities
@import './styles/utilities/cursor';
@import './styles/utilities/spacing';
@import './styles/utilities/visibility';
@import './styles/utilities/text';

// base
@import './styles/base/body';
@import './styles/base/forms';
@import './styles/base/headings';
@import './styles/base/text';

// layout
@import './styles/layout/layout';

// vendors
@import './styles/vendors/toastify';
@import './styles/vendors/reachui';
@import './styles/vendors/dateRangePicker';
@import './styles/vendors/typeahead';
@import './styles/vendors/recaptcha';
@import './styles/vendors/intlTelInput';
@import './styles/vendors/reactTable';
@import './styles/vendors/mapBox';
@import './styles/vendors/brands.min.css';
@import './styles/vendors/fontawesome.min.css';
@import './styles/vendors/light.min.css';
@import './styles/vendors/solid.min.css';

// atoms
@import './styles/atoms/checkbox';
@import './styles/atoms/description';
@import './styles/atoms/icons';
@import './styles/atoms/input';
@import './styles/atoms/link';
@import './styles/atoms/table';
@import './styles/atoms/text';
@import './styles/atoms/textarea';

// molecules
@import './styles/molecules/button';
@import './styles/molecules/switch';
@import './styles/molecules/labelInfo';
@import './styles/molecules/logo';
@import './styles/molecules/navigation';
@import './styles/molecules/noticeLabel';
@import './styles/molecules/select';
@import './styles/molecules/statusLed';
@import './styles/molecules/statusLabel';
@import './styles/molecules/tabset';
@import './styles/molecules/tag';
@import './styles/molecules/textField';
@import './styles/molecules/title';
@import './styles/molecules/uploadBox';
@import './styles/molecules/labelLed';
@import './styles/molecules/datePicker';
@import './styles/molecules/mapboxComponents';

// organisms
@import './styles/organisms/box';
@import './styles/organisms/agreementBox';
@import './styles/organisms/developerConsole';
@import './styles/organisms/card';
@import './styles/organisms/header';
@import './styles/organisms/inviteCard';
@import './styles/organisms/pricingtable';
@import './styles/organisms/reports';
@import './styles/organisms/section';
@import './styles/organisms/sidebar';
@import './styles/organisms/notice';
@import './styles/organisms/signupLinks';
@import './styles/organisms/verifyAddress';

// components
@import './components/Modal/modal';
@import './components/ReactTable/reactTable';
@import './components/TableCheckmark/tableCheckmark';
@import './components/ToggleButton/toggleButton';
