a {
  color: $color-links;
  font-size: 2rem;
  &:hover {
    color: $color-links-hover;
  }
  // &:visited {
  //   color: $color-links-active;
  // }
  &[disabled] {
    color: $color-gray-400;
  }
}
