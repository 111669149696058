.c-section {
  color: $color-gray-700;
  &__label {
    color: $color-gray-500;
    margin: 0 0 -1rem 0;
    text-transform: uppercase;
    font-size: $font-size-sm;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__key {
    margin-top: 1rem;
    line-height: 1;
  }
  &__value {
    &--bold {
      @extend .h2;
      color: $color-action-blue-700;
    }
  }
}

.c-section-alt {
  @extend .c-section;
  display: flex;

  @media all and (max-width: 500px) {
    flex-direction: column;
  }

  &__content {
    display: flex;
    align-items: center;

    @media all and (max-width: 500px) {
      justify-content: space-between;
    }
  }
  &__label {
    margin: 0 2rem 0 0;
    font-size: $font-size-med;
    font-weight: $font-weight-bold;
    color: $color-gray-500;
  }
  &__value {
    margin: 0 3rem 0 0;
  }
}
