// see base css from reachui '@reach/dialog/styles.css';

[data-reach-dialog-content] {
  margin: 0vh;
  @media all and (max-width: $bp-med) {
    width: 95vw;
  }
}

@keyframes fromTop {
  0% {
    top: -50vh;
    opacity: 0;
  }
  100% {
    top: 0vh;
    opacity: 1;
  }
}

@keyframes toTop {
  0% {
    top: 0vh;
    opacity: 1;
  }
  100% {
    top: -50vh;
    opacity: 0;
  }
}

.c-modal {
  margin: 10vh auto auto;
  max-width: 110rem;
  border-radius: 5px;
  position: relative;
  overflow: auto;
  animation-name: fromTop;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  top: 0vh;
  opacity: 1;

  &--wide {
    position: relative;
    width: 100%;
    max-width: 220rem;
    overflow: hidden;
    margin: 3vh auto auto;
  }

  &__close {
    @media all and (max-width: 400px) {
      display: none !important;
    }
    position: absolute;
    top: 2rem;
    right: 3rem;
  }
  &__heading {
    margin: 0;
    padding: 1rem 3rem;
  }
  &__body {
    padding: 0rem 3rem 3rem 3rem;
  }
}

.c-modal.closed {
  @extend .c-modal;
  animation-name: toTop;
  top: -50vh;
  opacity: 0;
}

@keyframes fromRight {
  0% {
    right: -50vw;
    opacity: 0;
  }
  100% {
    right: 0vw;
    opacity: 1;
  }
}

@keyframes toRight {
  0% {
    right: 0vw;
    opacity: 1;
  }
  100% {
    right: -50vw;
    opacity: 0;
  }
}

.c-modal-slider {
  position: fixed;
  animation-name: fromRight;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  right: 0;
  height: 100vh;
  border-radius: 5px;
  overflow-x: auto;
  opacity: 1;

  &--wide {
    width: 85vw;
  }

  &__close {
    position: fixed;
    right: 3rem;
  }
}

.c-modal-slider.closed {
  @extend .c-modal-slider;
  animation-name: toRight;
  right: -50vw;
  opacity: 0;
}
@keyframes fromLeft {
  0% {
    left: -100vw;
    opacity: 0;
  }
  100% {
    left: 0vw;
    opacity: 1;
  }
}

.c-modal-menu-slider {
  @extend .c-modal-slider;
  animation-name: fromLeft;
  left: 0;
  opacity: 1;
}

@keyframes toLeft {
  0% {
    left: 0vw;
    opacity: 1;
  }
  100% {
    left: -100vw;
    opacity: 0;
  }
}

.c-modal-menu-slider.closed {
  @extend .c-modal-menu-slider;
  animation-name: toLeft;
  left: -100vw;
  opacity: 0;
}
