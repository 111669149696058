.c-upload-box {
  max-width: 40rem;
  border: 2px dotted $color-gray-400;
  padding: 4.5rem 7.25rem;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  &__p {
    color: $color-action-blue-500;
    margin: 0;
    max-width: 20rem;
  }
}

.c-upload-box-drag-only {
  &__box {
    border-width: 2px;
    border-radius: 5px;
    border-style: dashed;
    border-color: $color-action-blue-500;
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    transition: border 0.24s ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
  &__is-dragging {
    border-color: $color-upload-box-border;
    background-color: $color-upload-box-background;
  }
}
