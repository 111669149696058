.c-notice-label {
  background: $color-action-blue-700;
  width: 100%;
  max-width: 40rem;
  border-radius: 8px;
  padding: 2rem;
  &__text {
    color: $color-white;
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }
}

.c-notice-label-alt {
  background: $color-gray-200;
  width: 100%;
  max-width: 40rem;
  border-radius: 8px;
  padding: 2rem;
  &__text {
    color: $color-action-blue-700;
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }
}

.c-section-box-alt {
  background: $color-box-bg;
  width: 100%;
  max-width: 40rem;
  border-radius: 8px;
  padding: 2rem;
  &__text {
    color: $color-action-blue-700;
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }
}
