.c-card {
  background-color: $color-box-bg;
  border-radius: 10px;
  padding: 1rem 4rem 30px;

  &__title {
    margin: 3rem 0;
    text-transform: uppercase;
  }
  &__description {
    margin: 3rem 0;
  }
  &__btn {
    margin: 3rem 0;
  }
}
