input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'] {
  padding: 0 2rem;
  height: 5rem;
  color: $color-gray-700;

  &:focus {
    border-color: $color-action-blue-500;
    outline: $color-action-blue-500;
    box-shadow: 0 0 0 0.05em lighten($color-action-blue-500, 35%),
      0 0 0.15em 0.1em lighten($color-action-blue-500, 35%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.c-checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 1rem;
  color: var(--color);

  &__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: $border-radius;
    border: 0.1em solid currentColor;

    svg {
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: bottom left;
    }
  }

  &__input {
    display: grid;

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 1em;
      height: 1em;

      &:focus + .c-checkbox__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
      }

      &:checked + .c-checkbox__control svg {
        transform: scale(1);
      }

      &:disabled + .c-checkbox__control {
        color: var(--disabled);
        cursor: not-allowed;
      }
    }
  }
}
